var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-6",attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',{staticClass:"px-6 card-header white--text"},[_c('v-icon',{staticClass:"mr-3 white--text"},[_vm._v("mdi-file-eye")]),_vm._v(" "),_c('h3',[_vm._v("API Data Sharing Log List")])],1),_c('v-row',{staticClass:"px-8 pt-4"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"From Date","prepend-icon":"mdi-calendar-start","readonly":"","outlined":"","dense":""},model:{value:(_vm.fdate),callback:function ($$v) {_vm.fdate=$$v},expression:"fdate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fmenu),callback:function ($$v) {_vm.fmenu=$$v},expression:"fmenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.fmenu = false}},model:{value:(_vm.fdate),callback:function ($$v) {_vm.fdate=$$v},expression:"fdate"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"To Date","prepend-icon":"mdi-calendar-end","readonly":"","outlined":"","dense":""},model:{value:(_vm.tdate),callback:function ($$v) {_vm.tdate=$$v},expression:"tdate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.tmenu),callback:function ($$v) {_vm.tmenu=$$v},expression:"tmenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.tmenu = false}},model:{value:(_vm.tdate),callback:function ($$v) {_vm.tdate=$$v},expression:"tdate"}})],1)],1),_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"4","align":"right"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"color":"excel","elevation":"2","disabled":_vm.newList == null},on:{"click":function($event){return _vm.exportReport('excel')}}},[_c('v-icon',{attrs:{"color":"white","center":"","dark":""}},[_vm._v(" mdi-microsoft-excel ")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 px-6 admin_table",attrs:{"headers":_vm.headersList,"items":_vm.newList,"items-per-page":10,"search":_vm.search},scopedSlots:_vm._u([{key:"item.dataValidationStatus",fn:function(){return [_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v(" Finish Processing ")])]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [[_c('v-icon',{staticClass:"mr-2 blue--text",attrs:{"small":""},on:{"click":function($event){return _vm.formRequest(item)}}},[_vm._v("mdi-pencil")])]]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }